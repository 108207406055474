import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  height: 100%;
  margin-bottom: 61px;
  padding: 0 20px;
`;

export const Content = styled.div`
  max-width: 1128px;
  display: flex;
  flex-direction: column;
`;

export const ContentTitle = styled.div`
  display: flex;
  margin-bottom: 43px;

  h1 {
    color: #ff4900;
    font-size: 40px;
    width: 100%;

    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
`;

export const Logo = styled.img`
  width: 78px;
  height: 71px;
`;

export const ContainerInformations = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  @media (max-width: 680px) {
    flex-direction: column;
  }

  figure {
    @media (max-width: 680px) {
      width: 100%;
    }
  }
`;

export const ContentText = styled.div`
  width: 50%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;

  @media (max-width: 680px) {
    width: 100%;
  }
`;

export const ContainerBenefits = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContainerBenefitsMobile = styled.div`
  display: none;
  width: 100%;

  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    display: flex;
  }

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;
