import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 114px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #a4a4a4;
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 1;

  @media (max-width: 600px) {
    height: 49px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerInfo = styled.div`
  border-bottom: 1px solid #a4a4a4;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Info = styled.div`
  width: 1128px;
  height: 48px;
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 1128px) {
    padding: 0 20px;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
  margin-left: 30px;
`;

export const List = styled.ul`
  display: flex;
  gap: 17px;
  font-size: 13px;

  li {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ListNavigation = styled.ul`
  display: flex;
  list-style: none;
  gap: 17px;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Item = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  color: #3c3c3b;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const Navigation = styled.div`
  display: flex;
  height: 66px;
  justify-content: space-between;
  max-width: 1128px;
  width: 100%;
  align-items: center;

  @media (max-width: 1128px) {
    padding: 0 20px;
  }
`;

export const Logo = styled.img`
  height: 100%;
  width: 165px;
  cursor: pointer;
`;

export const MenuMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`;
