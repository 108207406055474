import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.section`
  max-width: 1128px;
  width: 100%;
  min-height: 500px;
  margin-top: 30px;

  @media (max-width: 1128px) {
    padding: 0 20px;
  }
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerButton = styled.div`
  padding: 60px 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;
