import { Container } from "./styles";

const Title = ({ children, color, fontSize, subtitle }) => {
  return (
    <Container>
      <h1 style={{ color: color, fontSize: fontSize }}>{children}</h1>

      {subtitle && <h2>{subtitle}</h2>}
    </Container>
  );
};

export default Title;
