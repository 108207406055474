import { useLocation, useNavigate } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";

import Footer from "../footer";
import Header from "../header";
import Search from "../search";
import api from "../../api";
import Card from "../card";
import Button from "../button";
import Title from "../title";

import { Cards, Container, ContainerButton, Content } from "./styles";

const RenderSearchResults = () => {
  const navigate = useNavigate();

  const filterDefault = {
    results_per_page: 12,
    finality: ["residential"],
  };

  const query = new URLSearchParams(useLocation().search);

  const neighborhood = query.get("neighborhood");
  const city = query.get("city");
  const state = query.get("state");
  const status = query.get("status");
  const type = query.get("type");
  const reference = query.get("reference");
  const priceFrom = query.get("priceFrom");
  const priceTo = query.get("priceTo");
  const areaFrom = query.get("areaFrom");
  const areaTo = query.get("areaTo");

  console.log(city, state, neighborhood);

  const fetchProperties = async ({ page = 1 }) => {
    const params = {
      ...filterDefault,
      area: neighborhood ? [neighborhood] : undefined,
      city: city || undefined,
      state: state || undefined,
      status: status ? status.split(",") : undefined,
      type: type ? [type] : ["apartments", "house"],
      reference: reference || undefined,
      min_price: priceFrom || undefined,
      max_price: priceTo || 350000,
      min_private_area: areaFrom || undefined,
      max_private_area: areaTo || undefined,
      page: page,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, data]) => data !== undefined)
    );

    const response = await api.get("/buildings", {
      params: filteredParams,
    });

    return {
      buildings: response.data.buildings || [],
      totalPages: response.data.total_pages || 1,
      page: page,
    };
  };

  const {
    data = [],
    error,
    isLoading,

    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["fetchProperties", query.toString()],
    queryFn: ({ pageParam }) => fetchProperties({ page: pageParam }),
    getNextPageParam: (lastPage) =>
      lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
  });

  return (
    <Container>
      <Header fixed={false} />
      <Search />
      <Content>
        <Title color="#28711F" fontSize={30}>
          Resultado da sua pesquisa
        </Title>

        {isLoading && <p>Carregando...</p>}
        {error && <p>Erro ao carregar dados.</p>}
        {!isLoading && !error && (
          <Cards>
            {data?.pages.flatMap((page, pageIndex) =>
              page.buildings.map((item, index) => (
                <Card
                  key={`${item.id}-${pageIndex * 1000 + index}`}
                  name={item.name}
                  image={item.default_image["520x280"]}
                  dormitory={item.min_bedrooms}
                  location={item.address.area}
                  parkingSpaces={item.min_parking}
                  price={item.min_price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  squareMeters={item.min_area || 0}
                  suite={0}
                  card="default"
                  onClick={() => navigate(`/imovel/${item.id}`)}
                />
              ))
            )}
          </Cards>
        )}
      </Content>
      {hasNextPage && (
        <ContainerButton>
          <Button onClick={fetchNextPage} disabled={isFetchingNextPage}>
            {isFetchingNextPage ? "Carregando..." : "Carregar mais imóveis"}
          </Button>
        </ContainerButton>
      )}
      <Footer />
    </Container>
  );
};

export default RenderSearchResults;
