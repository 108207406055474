import { useNavigate, useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";

import Footer from "../footer";
import Header from "../header";
import Title from "../title";
import Card from "../card";
import api from "../../api";
import Button from "../button";

import { Container, ContainerButton, ContainerCards, Content } from "./styles";

const filter = {
  results_per_page: 12,
  type: ["apartments", "house"],
  finality: ["residential"],
  max_price: 350000,
};

const fetchProperties = async ({ page = 1, name }) => {
  const response = await api.get("/buildings", {
    params: {
      ...filter,
      status: name === "obras" ? ["under_construction"] : ["ready"],
      page: page,
    },
  });

  return {
    buildings: response.data.buildings || [],
    totalPages: response.data.total_pages || 1,
    page: page,
  };
};

const RenderCategory = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [`properties`, name],
    queryFn: ({ pageParam }) => fetchProperties({ page: pageParam, name }),
    getNextPageParam: (lastPage) =>
      lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
  });

  const renderCards = () => {
    return data?.pages.flatMap((page) =>
      page.buildings.map((item, index) => (
        <Card
          key={item.id || index}
          name={item.name}
          image={item.default_image["520x280"]}
          dormitory={item.min_bedrooms}
          location={item.address.area}
          parkingSpaces={item.min_parking}
          price={item.min_price.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
          squareMeters={item.max_area || 0}
          suite={0}
          card={name === "destaques" ? "" : "default"}
          onClick={() => navigate(`/imovel/${item.id}`)}
        />
      ))
    );
  };

  return (
    <Container>
      <Header fixed={false} />
      <Content>
        <Title
          color={name !== "destaques" ? "#28711F" : ""}
          subtitle={
            name === "destaques"
              ? "Imóveis selecionados  especialmente para você"
              : ""
          }
        >
          {name === "destaques"
            ? "Destaques Urblar"
            : name.charAt(0).toUpperCase() + name.slice(1).replace(/-/g, " ")}
        </Title>

        <ContainerCards
          style={{
            gridTemplateColumns: name === "destaques" ? "repeat(3, 1fr)" : "",
          }}
        >
          {isLoading && <p>Carregando...</p>}
          {error && <p>Erro ao carregar imóveis</p>}
          {!isLoading && !error && renderCards()}
        </ContainerCards>

        {hasNextPage && (
          <ContainerButton>
            <Button
              onClick={fetchNextPage}
              disabled={isFetchingNextPage}
              background={name === "destaques" ? "orange" : ""}
            >
              {isFetchingNextPage ? "Carregando..." : "Carregar mais imóveis"}
            </Button>
          </ContainerButton>
        )}
      </Content>
      <Footer />
    </Container>
  );
};

export default RenderCategory;
