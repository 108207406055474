import { ContainerButton, Icon } from "./styles";

import { IoSearch } from "react-icons/io5";

import IconDog from "../../assets/icons/urbinho.png";

const icons = {
  search: <IoSearch size={25} color="white" />,
  dog: <Icon src={IconDog} alt="icone" />,
};

const Button = ({
  backgroundColor,
  children,
  icon,
  background,
  disabled,
  onClick,
  button = "default",
}) => {
  return (
    <>
      {button === "default" ? (
        <ContainerButton
          style={{
            backgroundColor: backgroundColor,
            background:
              background === "orange"
                ? "linear-gradient(90deg, #FF4900 0%, #FE7814 100%)"
                : "linear-gradient(90deg, #28711F 0%, #77B011 100%)",
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
          {icon && icons[icon]}
        </ContainerButton>
      ) : (
        <ContainerButton
          style={{
            backgroundColor: backgroundColor,
          }}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
          {icon && icons[icon]}
        </ContainerButton>
      )}
    </>
  );
};

export default Button;
