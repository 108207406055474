import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  ContainerInfo,
  Content,
  Info,
  Item,
  List,
  ListNavigation,
  Logo,
  MenuMobile,
  Navigation,
  SocialIcons,
} from "./styles";

import { FaInstagram, FaFacebook, FaWhatsapp } from "react-icons/fa";

import { LuMail } from "react-icons/lu";
import { IoMenu } from "react-icons/io5";

import LogoSingular from "../../assets/logo.svg";
import SideBar from "../sideBar";

const Header = ({ fixed = true }) => {
  const navigate = useNavigate();
  const [sideBarShow, setSideBarShow] = useState(false);

  const showSideBar = () => {
    setSideBarShow(!sideBarShow);
  };

  return (
    <Container style={{ position: fixed === true ? "fixed" : "relative" }}>
      <Content>
        <ContainerInfo>
          <Info>
            <List>
              <li>
                <Item to="/sobre-nos">Sobre Nós</Item>
              </li>

              <li
                onClick={() =>
                  window.open("https://wa.me/5511947742696", "_blank")
                }
              >
                <FaWhatsapp color="#FF4900" />
                Fale com um corretor
              </li>
              <li
                onClick={() =>
                  document
                    .querySelector("footer")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                <LuMail color="#FF4900" />
                Contato
              </li>
            </List>
            <SocialIcons>
              <a
                aria-label="instagram urblar"
                href="https://www.instagram.com/urb.lar"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram color="#FF4900" />
              </a>

              <a
                aria-label="facebook urblar"
                href="https://www.facebook.com/ymkurblar"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaFacebook color="#FF4900" />
              </a>
            </SocialIcons>
          </Info>
        </ContainerInfo>

        <Navigation>
          <Logo alt="logo" src={LogoSingular} onClick={() => navigate("/")} />
          <ListNavigation>
            <li>
              <Item to="/imoveis/obras">LANÇAMENTOS/OBRAS</Item>
            </li>
            <li>
              <Item to="/imoveis/pronto-para-morar">PRONTO PARA MORAR</Item>
            </li>
          </ListNavigation>

          <MenuMobile>
            <IoMenu size={25} color="#FF4900" onClick={showSideBar} />
            {sideBarShow && (
              <SideBar visible={sideBarShow} setVisible={setSideBarShow} />
            )}
          </MenuMobile>
        </Navigation>
      </Content>
    </Container>
  );
};

export default Header;
