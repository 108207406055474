import styled from "styled-components";

export const ContainerButton = styled.button`
  max-width: 325px;
  width: 100%;
  height: 37px;
  border-radius: 61px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
`;

export const Icon = styled.img`
  position: absolute;
  right: 0px;
`;
