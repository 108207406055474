import { Container, Icon } from "./styles";

import IconIncome from "../../assets/icons/income.svg";
import IconFees from "../../assets/icons/fees.svg";
import IconFGTS from "../../assets/icons/fgts.svg";

const icons = {
  income: IconIncome,
  fees: IconFees,
  fgts: IconFGTS,
};

const Benefits = ({ backgroundColor, children, icon }) => {
  const SelectedIcon = icons[icon];

  return (
    <Container style={{ backgroundColor }}>
      {SelectedIcon && <Icon src={SelectedIcon} alt="icone" />}
      {children}
    </Container>
  );
};

export default Benefits;
