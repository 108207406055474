import Benefits from "../benefits";

import {
  Container,
  ContainerBenefits,
  ContainerBenefitsMobile,
  ContainerInformations,
  Content,
  ContentText,
  ContentTitle,
  Image,
  Logo,
} from "./styles";

import ImageCouple from "../../assets/couple.webp";
import LogoHome from "../../assets/logo2.svg";

const HousingBenefits = () => {
  return (
    <Container>
      <Content>
        <ContentTitle>
          <h1>Seu apartamento novo está aqui</h1>
          <Logo src={LogoHome} alt="logo" />
        </ContentTitle>

        <ContainerInformations>
          <figure>
            <Image src={ImageCouple} alt="imagem" />
          </figure>

          <ContentText>
            <div>
              <strong>
                <p>Transforme seu sonho da casa própria em realidade! </p>
              </strong>
              <br />

              <p>
                Agora, adquirir o seu apartamento está ao seu alcance. Com o
                subsídio do programa habitacional do Governo Federal, caso você
                tenha uma renda entre R$ 2.000,00 e R$ 4.500,00 mensais têm a
                oportunidade de dar entrada no financiamento e conquistar o lar
                perfeito.
                <br />
                <br /> Com a Urblar, esse sonho se torna ainda mais fácil e
                acessível.
              </p>
            </div>

            <ContainerBenefits>
              <Benefits backgroundColor="#FF4900" icon="income">
                Para renda de até R$4.500,00
              </Benefits>
              <Benefits backgroundColor="#FE7814" icon="fees">
                Taxas de juros menores
              </Benefits>
              <Benefits backgroundColor="#77B011" icon="fgts">
                Use seu saldo do FGTS
              </Benefits>
            </ContainerBenefits>
          </ContentText>
        </ContainerInformations>
        <ContainerBenefitsMobile>
          <Benefits backgroundColor="#FF4900" icon="income">
            Para renda de até R$4.500,00
          </Benefits>
          <Benefits backgroundColor="#FE7814" icon="fees">
            Taxas de juros menores
          </Benefits>
          <Benefits backgroundColor="#77B011" icon="fgts">
            Use seu saldo do FGTS
          </Benefits>
        </ContainerBenefitsMobile>
      </Content>
    </Container>
  );
};

export default HousingBenefits;
