import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Content = styled.section`
  max-width: 1128px;
  height: 100%;
  margin-top: 114px;
  padding: 30px 20px;

  @media (max-width: 600px) {
    margin-top: 49px;
  }
`;

export const Description = styled.p`
  font-size: 14px;
`;
