import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;
