import Title from "../../components/title";
import {
  Container,
  ContainerDescription,
  Content,
  Description,
  Image,
} from "./styles";

import ImageBroker from "../../assets/broker.webp";
import Header from "../../components/header";
import Footer from "../../components/footer";

const AboutUs = () => {
  return (
    <Container>
      <Header fixed={false} />
      <Content>
        <Title fontSize={35}>
          Transformamos o sonho da casa própria em realidade.
        </Title>

        <ContainerDescription>
          <Image src={ImageBroker} alt="imagem" />
          <Description>
            Na Urblar, não somos apenas uma imobiliária — somos sua parceira na
            conquista do lar ideal. Entendemos que realizar esse sonho pode
            parecer um desafio, mas estamos aqui para mostrar que é possível. E
            temos certeza de que você consegue! <br />
            <br />
            Ao longo de nossa trajetória, ajudamos muitas famílias a realizarem
            o sonho da casa própria. Representamos diversas construtoras
            renomadas, conectando você às melhores oportunidades do mercado
            imobiliário, com imóveis de qualidade a preços acessíveis. <br />
            <br />
            Com o programa habitacional Minha Casa Minha Vida, você pode
            utilizar benefícios como subsídio, FGTS e a composição de renda para
            facilitar seu financiamento. Além disso, na Urblar, oferecemos
            atendimento personalizado e condições de pagamento que realmente
            cabem no seu bolso.
            <br />
            <br /> Não importa se você acha que sua renda pode ser um obstáculo.
            Nossa equipe está pronta para encontrar as melhores soluções e
            ajudar você a dar o primeiro passo para conquistar o seu espaço.
            <br />
            <br />
            <strong>Vamos juntos realizar o seu sonho?</strong>
          </Description>
        </ContainerDescription>
      </Content>

      <Footer />
    </Container>
  );
};

export default AboutUs;
