import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.section`
  max-width: 1128px;
  width: 100%;
  margin: 31px 0 45px 0;
  min-height: 1000px;
`;

export const LoadingMessage = styled.div`
  font-size: 18px;
`;
