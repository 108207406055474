import styled from "styled-components";

import Background from "../../assets/background_banner.webp";

export const Container = styled.section`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin-bottom: 43px;
  margin-top: 114px;
  position: relative;
  height: 100%;

  @media (max-width: 600px) {
    margin-top: 49px;
  }
`;

export const ContainerBanner = styled.div`
  max-width: 1128px;
  width: 100%;
  display: flex;

  @media (max-width: 1128px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentText = styled.div`
  width: 50%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: white;
  font-size: 43px;
  margin-top: 50px;
  margin-bottom: 155px;

  @media (max-width: 768px) {
    margin-bottom: 100px;
    text-align: center;
  }
`;

export const Search = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    font-weight: bold;
    font-size: 24px;
    color: white;
    margin-bottom: 15px;
  }

  input {
    border-radius: 61px;
    height: 37px;
    border: none;
    padding-left: 10px;
    margin-bottom: 20px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  gap: 14px;

  @media (max-width: 520px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerImage = styled.figure`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: 100%;
`;
