import RenderProperties from "../renderProperties";

const FeaturedProperties = () => {
  return (
    <RenderProperties
      title="Oportunidades especiais"
      buttonText="+ VEJA MAIS OPÇÕES"
      titleColor="#28711F"
      queryKey={["featuredProperties"]}
      fetchParams={{
        results_per_page: 4,
      }}
      card="default"
      fontSize={30}
    />
  );
};

export default FeaturedProperties;
