import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import React, { useRef, useState } from "react";

const Location = ({ onChange }) => {
  const [addressDetails, setAddressDetails] = useState({
    neighborhood: "",
    city: "",
    state: "",
    country: "",
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCCNTdgrQX8NPjhxyUUVGDPcyL9nnO1jyY",
    libraries: ["places"],
  });

  const ref = useRef(null);

  const extractLocationDetails = (placeDetails) => {
    const addressComponents = placeDetails.address_components;

    const getComponent = (types, useShortName = false) => {
      const component = addressComponents.find((component) =>
        types.every((type) => component.types.includes(type))
      );
      return component
        ? useShortName
          ? component.short_name
          : component.long_name
        : null;
    };

    const neighborhood =
      getComponent(["neighborhood", "political"]) ||
      getComponent(["sublocality", "sublocality_level_1", "political"]) ||
      getComponent(["administrative_area_level_4", "political"]) ||
      getComponent(["administrative_area_level_3", "political"]);

    let city = getComponent(["locality", "political"]);
    if (!city) {
      city = getComponent(["administrative_area_level_2", "political"]);
    }

    const state = getComponent(
      ["administrative_area_level_1", "political"],
      true
    );
    const country = getComponent(["country", "political"]);

    return { neighborhood, city, state, country };
  };

  const handlePlaceChanged = () => {
    const place = ref.current.getPlace();
    const locationDetails = extractLocationDetails(place);

    setAddressDetails(locationDetails);
    if (onChange) {
      onChange(locationDetails);
    }
  };

  return (
    <>
      {isLoaded && (
        <Autocomplete
          onLoad={(autocomplete) => {
            ref.current = autocomplete;
          }}
          onPlaceChanged={handlePlaceChanged}
        >
          <input placeholder="" />
        </Autocomplete>
      )}
    </>
  );
};

export default Location;
