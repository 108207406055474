import Banner from "../../components/banner";
import Footer from "../../components/footer";
import Header from "../../components/header";
import FeaturedProperties from "../../components/highlights";
import HighlightsUrblar from "../../components/highlightsUrblar";
import HousingBenefits from "../../components/housingBenefits";

const Home = () => {
  return (
    <div>
      <Header />
      <Banner />
      <HousingBenefits />
      <HighlightsUrblar />
      <FeaturedProperties />

      <Footer />
    </div>
  );
};

export default Home;
