import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import api from "../../api";
import Button from "../button";
import Card from "../card";
import Title from "../title";

import { Container, Content, ContainerCards, ContainerButton } from "./styles";

const RenderProperties = ({
  title,
  subtitle,
  buttonText,
  titleColor,
  queryKey,
  fetchParams,
  colorButton,
  card,
  backgroundColor,
  fontSize,
  name,
}) => {
  const navigate = useNavigate();

  const fetchProperties = async () => {
    const response = await api.get("/buildings", {
      params: {
        ...fetchParams,
        type: ["apartments", "house"],
        finality: ["residential"],
        max_price: 350000,
      },
    });

    return response.data.buildings;
  };

  const {
    data = [],
    isLoading,
    error,
  } = useQuery({
    queryFn: fetchProperties,
    queryKey,
    staleTime: 1000 * 60 * 5,
  });

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  if (error) {
    return <p>Erro: {error}</p>;
  }

  return (
    <Container style={{ backgroundColor: backgroundColor }}>
      <Content>
        <Title color={titleColor} fontSize={fontSize} subtitle={subtitle}>
          {title}
        </Title>

        <ContainerCards>
          {data.map((item, index) => {
            const {
              id,
              name,
              default_image,
              address,
              min_area,
              min_parking,
              min_bedrooms,
              min_price,
            } = item;

            return (
              <Card
                key={id || index}
                name={name}
                image={default_image["520x280"]}
                dormitory={min_bedrooms}
                location={address.area}
                parkingSpaces={min_parking}
                price={min_price.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                squareMeters={min_area}
                suite={0}
                card={card}
                onClick={() => navigate(`/imovel/${id}`)}
              />
            );
          })}
        </ContainerCards>

        <ContainerButton>
          <Button
            background={colorButton}
            onClick={() =>
              name === "HighlightsUrblar"
                ? navigate("/imoveis/destaques")
                : navigate("/imoveis/oportunidades-especiais")
            }
          >
            {buttonText}
          </Button>
        </ContainerButton>
      </Content>
    </Container>
  );
};

export default RenderProperties;
