import RenderProperties from "../renderProperties";

const HighlightsUrblar = () => {
  return (
    <RenderProperties
      title="Destaques URBLAR"
      subtitle="Imóveis selecionados especialmente para você"
      buttonText="+ VEJA TODOS OS DESTAQUES"
      titleColor="#FF4900"
      queryKey={["realestateHightLights"]}
      fetchParams={{
        results_per_page: 3,
      }}
      colorButton="orange"
      backgroundColor="#F8F8F8"
      fontSize={40}
      name="HighlightsUrblar"
    />
  );
};

export default HighlightsUrblar;
