import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Content = styled.section`
  max-width: 1128px;
  height: 100%;
  padding: 30px 20px;
`;

export const ContainerDescription = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Description = styled.p`
  font-size: 14px;
  width: 100%;
`;
